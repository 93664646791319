<template>
  <div>
    <CRow>
      <CCol lg="12">
        <h1 class="main-header" v-translate translate-context="price_list_template">
          Price List Template List
        </h1>
        <CCard class="">
          <CCardHeader>
            <div class="card-header-actions">
              <router-link :to="{name: 'priceListTemplate.new'}" v-if="hasPerm('admin.price_list.create')">
                <CButton block color="primary" v-translate translate-context="list.actions">Create</CButton>
              </router-link>
            </div>
          </CCardHeader>
          <CCardBody class="px-0">
            <AjaxTable
                :fields="fields"
                :data="priceLists"
                :fetch="fetchPriceListList"
            >
              <template #actions="{item}">
                <td class="text-right">
                  <CButton size="sm" variant="outline" color="primary" class="mr-2"
                           v-if="hasPerm('admin.price_list.update')"
                           @click="$router.push({name: 'priceListTemplate.edit', params: {id: item.id}})"
                           v-translate translate-context="list.actions"
                  >
                    Edit
                  </CButton>
                  <CButton @click="onDelete(item.id)" size="sm" variant="outline" color="danger"
                           v-if="hasPerm('admin.price_list.delete')"
                           v-translate translate-context="list.actions"
                  >
                    Delete
                  </CButton>
                </td>
              </template>
            </AjaxTable>
          </CCardBody>
        </CCard>
      </CCol>
    </CRow>
  </div>
</template>

<script>
import {mapActions, mapGetters} from "vuex";
import AjaxTable from "@/domain/core/components/AjaxTable.vue";

export default {
  name: 'ListPriceList',
  components: {AjaxTable},
  data() {
    return {
      fields: [
        {key: 'id', label: this.$pgettext('price_list_template.list','ID'), _style: 'width:100px;'},
        {key: 'display_name', label: this.$pgettext('price_list_template.list','Name')},
        {key: 'currency', label: this.$pgettext('price_list_template.list','Currency')},
        {key: 'actions', label: this.$pgettext('price_list_template.list','Actions'), sorter: false, filter: false}
      ],
    }
  },
  async mounted() {
    await this.fetchConfig({type:'admin'})
  },
  computed: {
    ...mapGetters('priceListTemplate', [
      'priceLists',
    ]),
  },
  methods: {
    ...mapActions('priceListTemplate', [
      'fetchPriceListList',
      'deletePriceList'
    ]),
    ...mapActions('login', [
      'fetchConfig'
    ]),
    onDelete(id) {
      this.confirm({
          title: 'Are your sure?',
          message: 'This action is irreversible',
          cb_confirm: async () => {
            await this.deletePriceList(id)
            await this.fetchList()
          }
      })
    }
  }
}
</script>